<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-6">
        <div class="ibox">
          <div class="ibox-title">
            <h5>
              API Request Chart
              <small>(All Request)</small>
            </h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="form-group col-md-3">
                <label>Type</label>
                <select class="form-control" :id="typeId"></select>
              </div>
              <div class="form-group col-md-3">
                <label>Color</label>
                <select class="form-control" :id="colorId"></select>
              </div>
              <div class="form-group col-md-4">
                <label>
                  Date *
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Only 7 days date selection allowed"
                  ></i>
                </label>
                <div class="input-group date">
                  <input
                    class="form-control"
                    type="text"
                    :name="datePicker"
                    :id="datePicker"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group" style="margin-top: 3.6%">
                <button
                  type="button"
                  class="btn btn-success btn-lg ladda-button ladda-button-demo"
                  data-style="zoom-in"
                  @click="viewTimeSeriesChart"
                >
                  <i class="fa fa-bar-chart"></i>&nbsp;&nbsp; View
                </button>
              </div>
            </div>

            <div>
              <canvas :id="timeSeriesId"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="ibox">
          <div class="ibox-title">
            <h5>
              API Donut Chart
            </h5>
          </div>
          <div class="ibox-content">
            <div>
              <canvas :id="doughnutChartId"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chart from "../plugin/chart";
import Utils from "../plugin/Utils";
import SearchCriteria from "../../framework/plugin/SearchCriteria";
import Constant from "../../framework/plugin/Constant";
import AxiosService from "../../framework/plugin/AxiosService";

export default {
  data() {
    return {
      colorId: "colorChart",
      typeId: "chartType",
      timeSeriesId: "timeSeriesId",
      doughnutChartId: "doughnutChartId",
      typeData: [
        { id: "bar", text: "Bar" },
        { id: "line", text: "Line" }
      ],
      colorData: [
        { id: "green", text: "Green" },
        { id: "red", text: "Red" },
        { id: "orange", text: "Orange" },
        { id: "yellow", text: "Yellow" },
        { id: "blue", text: "Blue" },
        { id: "purple", text: "Purple" },
        { id: "grey", text: "Grey" }
      ],
      datePicker: "datefilter"
    };
  },
  mounted() {
    Ladda.bind(".ladda-button", { timeout: 300 });
    Utils.initalizedSelect2("#" + this.typeId, this.typeData);
    Utils.initalizedSelect2("#" + this.colorId, this.colorData);
    Utils.datePickerInitalizer(this.datePicker, 30);
    this.getChart();
  },
  methods: {
    viewTimeSeriesChart: function() {
      chart.gnerateTimeSeriesChart(
        $("#" + this.datePicker).val(),
        this.timeSeriesId,
        $("#" + this.typeId).val(),
        $("#" + this.colorId).val()
      );
    },
    getChart: function() {
      chart.gnerateTimeSeriesChart($("#" + this.datePicker).val(), this.timeSeriesId, $("#" + this.typeId).val(), $("#" + this.colorId).val());
      chart.apiDonutChart(this.doughnutChartId);
    }
  }
};
</script>