"use strict";
import Constant from './Constant';
import Utils from "./Utils";
import SearchCriteria from "./SearchCriteria";
import AxiosService from "./AxiosService";

var Chart = require("chart.js");
moment.suppressDeprecationWarnings = true;
var timeSeriesChartVar;
var doughnut;
var gnerateTimeSeriesChart = function (date, chartId, type, color) {
    var searchCriteriaData = [];
    var apiTimeSeriesChartdata = [];
    if (!Utils.isEmptyStr(date)) {
        var data = Utils.splitStr(date, ":", 2);
        searchCriteriaData.push(new SearchCriteria(Constant.data.CALL_TIME_COLUMN, Utils.trimStr(data[0]) + " 00:00:00", Constant.criteria.GREATER_THAN_EQUAL));
        searchCriteriaData.push(new SearchCriteria(Constant.data.CALL_TIME_COLUMN, Utils.trimStr(data[1]) + " 23:59:59", Constant.criteria.LESS_THAN_EQUAL));
        var self = this;
        AxiosService.post(Constant.url.METRICS_URI_TIMESERIES_CHART_URI, searchCriteriaData).then(result => {
            $.each(result, function (key, value) {
                apiTimeSeriesChartdata.push({
                    t: key,
                    y: value
                });
            });
            if(Utils.isEmptyStr(apiTimeSeriesChartdata)) {
                apiTimeSeriesChartdata.push({
                    t: moment().format('MMM DD YYYY'),
                    y: 0
                });
            }
            self.timeSeriesChart(chartId, type, color, apiTimeSeriesChartdata);
        });
    } else {
        AxiosService.get(Constant.url.GET_TIMESERIES_CHART_URI).then(result => {
            $.each(result, function (key, value) {
                apiTimeSeriesChartdata.push({
                    t: key,
                    y: value
                });
            });
            timeSeriesChart(chartId, type, color, apiTimeSeriesChartdata);
        });
    }
}

var timeSeriesChart = function (canvasId, type, chartColor, data) {
    if(timeSeriesChartVar && timeSeriesChartVar.destroy) {
        timeSeriesChartVar.destroy();
    }
    var ctx = document.getElementById(canvasId).getContext('2d');
    var title = "Total API Request";
    var barTitle = "API Request";
    ctx.canvas.width = 800;
    ctx.canvas.height = 300;

    var color = Chart.helpers.color;
    var cfg = {
        data: {
            datasets: [{
                label: barTitle,
                backgroundColor: color(chartColor).alpha(0.5).rgbString(),
                borderColor: chartColor,
                data: data,
                type: type,
                pointRadius: 0,
                fill: false,
                lineTension: 0,
                borderWidth: 2
            }]
        },
        options: {
            animation: {
                duration: 0
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    offset: true,
                    ticks: {
                        major: {
                            enabled: true,
                            fontStyle: 'bold'
                        },
                        source: 'data',
                        autoSkip: true,
                        autoSkipPadding: 75,
                        maxRotation: 0,
                        sampleSize: 100
                    },
                    afterBuildTicks: function (scale, ticks) {
                        var majorUnit = scale._majorUnit;
                        var firstTick = ticks[0];
                        var i, ilen, val, tick, currMajor, lastMajor;

                        val = moment(ticks[0].value);
                        if ((majorUnit === 'minute' && val.second() === 0)
                            || (majorUnit === 'hour' && val.minute() === 0)
                            || (majorUnit === 'day' && val.hour() === 9)
                            || (majorUnit === 'month' && val.date() <= 3 && val.isoWeekday() === 1)
                            || (majorUnit === 'year' && val.month() === 0)) {
                            firstTick.major = true;
                        } else {
                            firstTick.major = false;
                        }
                        lastMajor = val.get(majorUnit);

                        for (i = 1, ilen = ticks.length; i < ilen; i++) {
                            tick = ticks[i];
                            val = moment(tick.value);
                            currMajor = val.get(majorUnit);
                            tick.major = currMajor !== lastMajor;
                            lastMajor = currMajor;
                        }
                        return ticks;
                    }
                }],
                yAxes: [{
                    gridLines: {
                        drawBorder: false
                    },
                    scaleLabel: {
                        display: true,
                        labelString: title
                    }
                }]
            },
            tooltips: {
                intersect: false,
                mode: 'index',
                callbacks: {
                    label: function (tooltipItem, myData) {
                        var label = myData.datasets[tooltipItem.datasetIndex].label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += parseFloat(tooltipItem.value).toFixed(2);
                        return label;
                    }
                }
            }
        }
    };
    timeSeriesChartVar = new Chart(ctx, cfg);
}

var apiDonutChart = function(chartId) {
    var title = 'API Donut Chart';
    var backgroundColorData = [ 
        window.chartColors.green,
        window.chartColors.blue,
        window.chartColors.red
    ];
    var labelsData = [
        'Total Request',
        'Success',
        'Error'
    ];
    AxiosService.get(Constant.url.GET_DONUTCHART_URI).then(result => {
        var donutData = Utils.splitStr(result, ':', 3);
        donutChart(chartId, title, backgroundColorData, labelsData, donutData);
    });
}

var donutChart = function(chartId, title, backgroundColorData, labelsData, donutData) {
    if(doughnut && doughnut.destroy) {
        doughnut.destroy();
    }
    var config = {
        type: 'doughnut',
        data: {
            datasets: [{
                data: donutData,
                backgroundColor: backgroundColorData,
                label: 'Dataset 1'
            }],
            labels: labelsData
        },
        options: {
            responsive: true,
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title
            },
            animation: {
                animateScale: true,
                animateRotate: true
            }
        }
    };

        var ctx = document.getElementById(chartId).getContext('2d');
        doughnut = new Chart(ctx, config);
}

export default {
    timeSeriesChart,
    gnerateTimeSeriesChart,
    apiDonutChart
}